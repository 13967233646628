<template>
  <default-layout>
    <template slot="toolbar">
      <slot name="appDrawer"></slot>
      <v-toolbar-title class="pl-1">
        <v-icon v-if="$store.state.pageIcon" class="pb-1 pr-2">{{ $store.state.pageIcon }}</v-icon>
        <span>{{ $store.state.pageTitle }}</span>
      </v-toolbar-title>
    </template>

    <v-container>
      <div class="text-h6 ml-4 mb-2">
        <v-icon color="blue">{{ $store.state.pageIcon }}</v-icon>
        {{ $store.state.pageTitle }}
      </div>
      <v-divider class="mb-4"></v-divider>
      <div v-for="(s, i) in agendas" :key="i">
        <div class="body-2 ml-4 mb-0" style="width: 100%">
          <v-icon color="blue">mdi-calendar-clock</v-icon>
          {{ appDateFormat(s.marked_date) }}
        </div>
        <div class="py-0">
          <v-list dense height="100%" width="100%" class="mx-0">
            <v-list-item-group color="primary">
              <v-list-item
                v-for="(a, i) in s.agenda"
                :key="i"
                @click="viewInfo(a.id)"
                two-line
              >
                <v-list-item-content>
                  <v-list-item-title v-text="a.title"></v-list-item-title>
                  <v-list-item-subtitle
                    v-text="'Published Date : ' + a.publish_date"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>
      </div>

      <v-alert color="warning" v-if="(loading == false) && (agendas.length == 0)"
        >Oppss.. Belum ada data.</v-alert
      >
    </v-container>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/DefaultLayout.vue';
export default {
  name: "AgendaList",
  components: {
    DefaultLayout,
  },

  data: () => ({
    agendas: [],
    loading: false,
  }),

  methods: {
    getList() {
      this.loading = true
      this.$axios.get("agenda/list").then((res) => {
        this.$store.state.showLoadingDialog = false;
        this.loading = false
        if (res.data.status == "success") {
          this.agendas = res.data.data;
        } else {
          this.agendas = [];
        }
      });
    },
    viewInfo(id) {
      this.$router.push("/agenda-list/" + id + "/info");
    },
  },

  mounted() {
    this.getList();
    this.setPageTitle("Agenda", "mdi-calendar");
  },

  
};
</script>